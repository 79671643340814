<template>
  <div class="login footer-margin bg-img">
    <breadcrumb style="position:static;"></breadcrumb>
    <div class="container-fluid blank">
      <div id="login-row" class="row justify-content-center align-items-center blank-section">
        <div id="login-column">
          <div id="login-box" class="col-12">
            <form>
              <div class="login-info">
                <h3 class="text-center text-info fw700">Увійдіть в систему</h3>
                <p>Зареєструйтеся на Kiel.RefugeeCompass, щоб запам'ятати інформацію для подальшого використання.</p>
              </div>

              <div class="form-group">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">email</i>
                  <input type="text" class="form-control form-control-rtl" @blur="validateEmailInput" v-model="credentials.email" placeholder="e-mail">
                  <span class="validation-error" v-show="showEmailError">Неправильний формат електронної пошти.</span>
                  <span class="validation-error" v-show="showEmptyEmailError">Неправильний формат електронної пошти.</span>

                </div>
              </div>
              <div class="form-group" style="margin-bottom: 0px;">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">lock</i>
                  <input :type="pwdType" class="form-control form-control-rtl" @blur="validatePasswordInput" v-model="credentials.password" placeholder="пароль">                 
                  <span @click="showPwd" class="material-icons ml-auto show-pwd" data-wg-notranslate="">{{pwdIcon}}</span>    
                  <span class="validation-error" v-show="showPasswordError">Введіть пароль. Будь ласка, зверніть увагу на вимоги, наведені нижче.</span>
                </div>
              </div>

              <div class="form-group text-right mt20 mb20 d-flex justify-content-end">
                <router-link :to="{ name: 'applyForNewPassword'}">
                  Забули пароль?
                </router-link>
              </div>

              <div class="form-group logn-group justify-content-center">
                <button type="submit" @click="dispatchLogin" class="btn btn-hover btn-fill btn-focus-design login-btn">Увійдіть в систему</button>
              </div>

              <div class="login-row">
                <div class="text-center mb-2">Ще не прийшли?</div>
                <router-link class="btn register-btn" title="Створіть новий обліковий запис користувача" :to="{ name: 'Signup'}">
                  Зареєструватися
               </router-link>
             </div>


           </form>
         </div>
       </div>
     </div>
   </div>


 </div>
</template>

<script>
  import {verify, csrf } from '@/api/auth';
  import { validEmail, validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'login',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
    },
    data() {
      return {
       credentials: {
        email: "",
        password: "",
      },
      showEmailError: false,
      showEmptyEmailError: false,
      showPasswordError: false,
      afterloginModal: false, 
      isFirstRegistrationEver: false,
      loader: false,
      pwdType: 'password',
      pwdIcon: 'visibility'
    };
  },
  beforeCreate() {
    /*Wenn es um eine verifizierung geht*/
    if(this.$route.params.token){
      verify(this.$route.params.token).then( response => {
        this.$notify({
          duration: 2500,
          title: "E-Mail Adresse verifiziert!",
          text: response
        });
      }).catch(() => {
        this.$notify({
          duration: 2500,
          title: "Verifizierung fehlgeschlagen!",
          text: "Die Verifizierung deiner E-Mail Adresse hat nicht funktioniert. Versuche es später noch einmal oder kontaktiere uns, wenn du dich nicht einloggen kannst."
        });
      });
    }
  },
  mounted(){
    if(this.$route.query.afterRegistration){
      this.afterloginModal = true;
    }
  },
  computed:{
    token(){
      return this.$store.getters.getToken;
    },
  },
  methods: {
    verify,
    showLoader,
    hideLoader,
    validEmail,
    validatePassword,
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdIcon = 'visibility_off';
        this.pwdType = '';
      } else {
        this.pwdIcon = 'visibility';
        this.pwdType = 'password';
      }
    },
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validateEmailInput(){
      this.credentials.email = this.credentials.email.replace(/\s+/g, '');
      if(!validEmail(this.credentials.email)){
        if(this.credentials.email !== ""){
          this.showEmailError=true;
          this.showEmptyEmailError=false;
        }
        else{
          this.showEmptyEmailError=true;
          this.showEmailError=false;
        }
        return false;
      }
      else{
        this.showEmailError=false;
        this.showEmptyEmailError=false;
        return true;
      }
    },
    dispatchLogin(e){
      e.preventDefault();
      var emailValid = this.validateEmailInput();
      var passwordValid = this.validatePasswordInput();
      if(!emailValid || !passwordValid){
        return;
      }

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        this.$store.dispatch('login', this.credentials).then(() => {
          this.$notify({
            duration: 2500,
            title: "Erfolgreich angemeldet!",
            text: "Du hast dich erfolgreich im Kiel.RefugeeCompass angemeldet."
          });

          /*load the Collections for the user*/
          this.$store.dispatch('loadUserCollections');
          this.$router.push({name:'Home'});

        }).catch(error => {
          this.$notify({
            duration: 4500,
            type: 'error',
            title: "Fehler bei der Anmeldung",
            text: error.data
          });
        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .validation-error {
    color: red;
  }

  .login {
    position: relative;
    padding-top:30px;
    background-color: #FFF;
    margin-bottom: 62px;
    
    @media (min-width: 992px) {
      margin-bottom: 256px;
      height: 100vh;
    }

    @media (max-width: 991px) {
      padding-top: 0px;
    }

    .anbieter-notice{
      margin-top:30px; 
      text-align:center;

      a{
        color:#157FAC;

        &:hover{
          color:#0d6efd;
        }
      }
    }

    .blank{
      display: flex;
      align-items: center;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #login-column{
        width: 100%;

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 15px;
        font-size: 36px;
      }

      .login-info {
        margin-bottom: 15px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
          width: 100%;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 9px;
          pointer-events: none;
          color: #157FAC;
        }

        .left-addon input {
          padding-left: 40px;
        }
      }
    }
  }

  .btn-focus-design:hover {
    background-color: #203B73;
  }

  .register-btn {
    color: #2E55A5;
    background-color: #FFF;
    border: 2px solid #157FAC;
    margin-bottom: 15px;

    &:hover {
      color: #FFF;
      background-color: #2E55A5;
    }
  }

  .register-btn, .login-btn {
    width: 50%;

    @media (max-width: 991px) {
      width: 90%;
    }
  }

  .bg-img {
   background-image: url("/assets/RefugeeCompass_bg_rotated_opacity.png");
   background-position: 120% 100%;
   background-size: 850px 850px;
   background-repeat: no-repeat;
   background-color: #fff;

   @media(max-width:991px) {
    background-size: 500px 500px;
  }

  @media(max-width:450px) {
    background-position: top -10px right -80px;
    background-size: 350px 350px;
  }
}

body[dir="rtl"] {
  .bg-img {
    background-position: top left -50px;

    @media(max-width:991px) {
      background-position: top -25px left -60px;
    }
  }

  .show-pwd {
    right: auto !important;
    left: 0 !important;
  }
}


</style>
